import xIcon from '@/components/Common/Icons/xIcon';

export default {
    name: 'ImageInput',
    props: {
        value: {
            type: String,
        },
        image: {
            type: String,
            default: null
        },
        error: {
            type: Boolean,
            default: false
        }
    },
    components: {
        xIcon,
    },
    mounted() {
    },
    methods: {
        removeImage() {
            this.$emit('update:modelValue', null);
        }
    }
}