<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div class="">
    <div class="container">
      <div class="row" v-if="userInfo">
        <div class="col-md-10" v-if="$store.state.user.info.type === 'vendor'">
          <VendorProfileSettings/>
        </div>
        <div class="col-md-10" v-else>
          <div class="add-property-title"> {{ $t('settings') }}</div>
          <div class="row">
            <div class="col-md-12 input-item">
              <div class="input-title-item" v-if="$store.state.user.info.type === 'agency'"> {{
                  $t('agency-name')
                }}
              </div>
              <div class="input-title-item" v-if="$store.state.user.info.type === 'user'"> {{
                  $t('your-name')
                }}
              </div>
              <div>
                <InputField :inputtype="'text'" v-model="userInfo.name" :content="userInfo.name"
                            :label="$t('enter-name')" :error="formErrors.includes('name')"/>
              </div>
            </div>
            <div class="col-md-12 input-item">
              <div class="input-title-item">Email</div>
              <div>
                <InputField :inputtype="'text'" v-model="userInfo.email" :content="userInfo.email"
                            :label="$t('enter-email-placeholder')" :error="formErrors.includes('email')"/>
              </div>
              <div class="add-button" v-if="$store.state.user.info.type === 'agency'">
                <Button :size="'small'" :theme="'solid-grey'" :text="$t('add-email-button')" :svg="'plus'"/>
              </div>
            </div>
            <div class="col-md-12 input-item">
              <div class="input-title-item"> {{ $t('contact-phone') }}</div>
              <div>
                <InputField :inputtype="'text'" v-model="userInfo.phone" :content="userInfo.phone"
                            :label="$t('enter-phone-placeholder')" :error="formErrors.includes('phone')"/>
              </div>
              <div class="add-button" v-if="$store.state.user.info.type === 'agency'">
                <Button :size="'small'" :theme="'solid-grey'" :text="$t('add-phone-button')" :svg="'plus'"/>
              </div>
            </div>
            <div class="col-md-12 input-item" v-if="$store.state.user.info.type === 'agency'">
              <div class="input-title-item"> {{ $t('profile-picture') }}</div>
              <div class="">
                <FileInput @change="e => uploadPhoto(e)"/>
              </div>
            </div>
            <div class="col-md-12 input-item" v-if="$store.state.user.info.type === 'agency'">
              <div class="input-title-item"> {{ $t('social-networks') }}</div>
              <div class="input-item">
                <div class="amenity-item">Facebook</div>
                <InputField :inputtype="'url'" :label="$t('enter-link')"/>
              </div>
              <div class="input-item">
                <div class="amenity-item">Instagram</div>
                <InputField :inputtype="'url'" :label="$t('enter-link')"/>
              </div>
            </div>

            <div class=" premium-item button-save col-md-4">
              <Button :theme="'solid-green'" :size="'medium'" :text="$t('save')" @click="save()"/>
            </div>

            <div class="col-md-12 input-item password-wrapper mt-5">
              <div class="input-title-item"> {{ $t('change-password') }}</div>
              <div class="input-description ">
                {{ $t('change-password-paragraph') }}
              </div>
              <div class="col-md-4">
                <Button :size="'medium'" :theme="'solid-grey'" :text="$t('change-password')" @click="changePassword()"/>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

