import Button from "@/components/Common/Buttons/Button/Button";
import InputField from "@/components/Common/Inputs/Input/Input";
import FileInput from "@/components/Common/Inputs/FileInput/FileInput";
import TextArea from "@/components/Common/Inputs/TextArea/TextArea";
import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import CheckBox from "@/components/Common/Inputs/CheckBox/CheckBox";
import YesNoInput from "@/components/Common/Inputs/YesNoInput/YesNoInput";
import MakePremium from "@/components/Components/MakePremium/MakePremium/";
import PremiumAdSelection from "@/components/Components/PremiumAdSelection/PremiumAdSelection/";
import CreditWallet from "@/components/Components/CreditWallet/CreditWallet/";
import AdUpdate from "@/components/Components/AdUpdate/AdUpdate/";
import UserTypeMobile from "@/components/Header/HeaderMobile/UserTypeMobile/UserTypeMobile";
import UserNavMobile from "@/components/Header/HeaderMobile/UserNavMobile/UserNavMobile";
import FileService from '@/services/FileService';
import VendorProfileSettings from "@/views/AdminSettings/VendorProfileSettings/VendorProfileSettings";


export default {
    name: 'AdminSettings',
    components: {
        VendorProfileSettings,
        Button,
        CheckBox,
        MakePremium,
        AdUpdate,
        PremiumAdSelection,
        CreditWallet,
        TextArea,
        Dropdown,
        InputField,
        UserTypeMobile,
        UserNavMobile,
        YesNoInput,
        FileInput,
    },
    data() {
        return {
            usertype: 'agency',
            categoriesMenuActive: false,
            userInfo: {
                name: null,
                email: null,
                phone: null,
            },
            userPassword: {
                password: null,
                password2: null,
            },
            formErrors: [],
            passFormErrors: [],
        };
    },
    methods: {
        async save() {
            if (!this.$store.state.user.authenticated) {
                await this.$store.dispatch("utils/openLoginModal");
                this.$router.push({name: 'Index'});
                return;
            }

            this.formErrors = [];
            if (this.userInfo.name === null || this.userInfo.name === '') {
                this.formErrors.push('name');
            }
            if (this.userInfo.phone === null || this.userInfo.phone === '') {
                this.formErrors.push('phone');
            }
            if (this.userInfo.email === null || this.userInfo.email === '') {
                this.formErrors.push('email');
            }
            if (this.formErrors.length > 0) {
                return;
            }

            try {
                await this.$store.dispatch("user/updateUser", this.userInfo);
                this.$store.dispatch("utils/openNotificationModal", {
                    type: 'success',
                    message: this.$t('your-profile-was-successfully-updated')
                });
            } catch (error) {
                this.$store.dispatch("utils/openNotificationModal", {
                    type: 'error',
                    message: this.$t('error-try-again')
                });
            }
        },
        async changePassword() {
            try {
                await this.$store.dispatch("user/requestChangePasswordMail", this.userInfo);
                this.$store.dispatch("utils/openNotificationModal", {
                    type: 'success',
                    message: this.$t('password-reset-instructions-sent-to-mail')
                });
            } catch (error) {
                this.$store.dispatch("utils/openNotificationModal", {
                    type: 'error',
                    message: this.$t('error-try-again')
                });
            }
        },
        uploadPhoto(event) {
            const file = event.target.files[0];
            FileService.uploadFile(file);
        },
    },
    async mounted() {
        await this.$store.dispatch("user/loadAuthenticatedUser");
        this.userInfo = await this.$store.state.user.info;
    },
};
